
import React from 'react'
import Pricing from '../templates/pricing'

const PricingEsPage = () => {
    return (
        <Pricing lang="en">
        </Pricing>

    )
}
export default PricingEsPage
